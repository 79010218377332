import {ModuleWithProviders, NgModule} from "@angular/core";

import {COMPONENT_MANIFESTS} from "@app/_core/features/component-loader/tokens/manifest-token";
import {DynamicContainerDirective} from "@app/_core/features/component-loader/directives/dynamic-container.directive";
import {DynamicComponentService} from "@app/_core/features/component-loader/services/dynamic-component.service";
import {DynamicComponentManifest} from "@app/_core/features/component-loader/models/dynamic-component-manifest";


@NgModule({
    declarations: [
        DynamicContainerDirective
    ],
    exports: [
        DynamicContainerDirective
    ],
    providers: [DynamicComponentService]
})
export class ComponentLoaderModule {
    public static forRoot(manifests: DynamicComponentManifest[]): ModuleWithProviders<ComponentLoaderModule> {
        return {
            ngModule: ComponentLoaderModule,
            providers: [
                DynamicComponentService,
                {
                    provide: COMPONENT_MANIFESTS,
                    useValue: manifests,
                    multi: true
                }
            ]
        }
    }
}
