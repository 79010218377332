export enum UserFeaturesEnum {
    FR_01_CanEditMacros = 'RZS_FR_01',
    FR_03_SectionAccess = 'RZS_FR_03',
    FR_07_AllTicketsAccess = 'RZS_FR_07',
    FR_11_OpinionAccess = 'RZS_FR_11',
    FR_13_HiddenFieldsAccess = 'RZS_FR_13',
    FR_14_MultiEditAccess = 'RZS_FR_14',
    FR_15_CanUnlockTicket = 'RZS_FR_15',
    FR_16_ViewTicketsWithoutBlocking = 'RZS_FR_16',
    FR_18_SignalRAccess = 'RZS_FR_18',
    FR_19_RequireApproveComments = 'RZS_FR_19',
    FR_20_CanApproveComments = 'RZS_FR_20',
    FR_21_FacebookLoginAccess = 'RZS_FR_21',
    FR_22_CanCloneTicket = 'RZS_FR_22',
    FR_23_CanCreateProblem = 'RZS_FR_23'
}
