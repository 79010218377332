import {createAction, props} from "@ngrx/store";
import {LoginRequestModel} from "@app/shared/models/login-request.model";
import {LoginResponseModel} from "@app/shared/models/login-response.model";

export const LogIn = createAction("[Auth service] Login", props<LoginRequestModel>())
export const LogInDomain = createAction("[Auth service] Domain Login")
export const LogInSuccess = createAction("[Auth service] Login Success", props<LoginResponseModel>());
export const LoginFailure = createAction("[Auth service] Login Failure", props<{
    errors: string[]
}>());

export const Logout = createAction("[Auth service] Logout");
export const CleanAuthDataAndRedirect = createAction("[Auth service] Clean Auth Data And Redirect");
export const CheckAuth = createAction("[Auth service] Check Auth");

