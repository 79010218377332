import {JsonUtils} from "./json.utils";
import {IntrospectionQuery, IntrospectionSchema} from "graphql/utilities/getIntrospectionQuery";
import {ResolvedScheme} from "@app/_core/models/resolved-scheme";
import {environment} from "../../../environments/environment";


export class GraphqlSchemaUtils {

    static getSchemaFromLocalstorage(): IntrospectionSchema {
        let localScheme: ResolvedScheme = JsonUtils.JSONParse<ResolvedScheme>(localStorage.getItem(environment.localStorageName.graphqlScheme));

        if (localScheme && localScheme.schema) {
            return localScheme.schema;
        }
        return null;
    }


    static saveSchemaInLocalstorage(schema: IntrospectionQuery): void {
        let resolvedScheme: ResolvedScheme = {
            resolvedDate: new Date(),
            schema: schema.__schema
        };
        localStorage.setItem(environment.localStorageName.graphqlScheme, JSON.stringify(resolvedScheme));
    }


    static deleteSchemaFromLocalstorage(): void {
        localStorage.removeItem(environment.localStorageName.graphqlScheme);
    }


    static isExpired(): boolean {
        let localScheme: ResolvedScheme = JsonUtils.JSONParse<ResolvedScheme>(localStorage.getItem(environment.localStorageName.graphqlScheme));
        let now = new Date();

        if (localScheme && localScheme.resolvedDate) {
            let resolvedDate = new Date(localScheme.resolvedDate);
            let diff = Math.abs(now.getTime() - resolvedDate.getTime());
            return (diff / 1000 / 60) >= 60
        }
        return true;
    }
}
