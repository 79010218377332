interface AuthSettings {
    apiUrl: string,
    logoutUrl: string;
    domainAuthUrl: string;
}

export interface Environment {
    production: boolean;
    appName: string;
    supportEmail: string;
    baseUrl: string;
    uploadFileSettings: UploadFileSettings;
    auth: AuthSettings;
    getFullEmailMessageUrl: string;
    invalidTicketIdUrl: string;
    signalrUrl: string;
    oldSearchUrl: string;
    transloco: TranslocoEnvironment;
    localStorageName: EnvironmentLocalStorageNames;
    httpHeaderName: EnvironmentHttpHeaderNames;
    emojiForServiceTicketTitle: string;
    find: FindEnvironment;
    problems: ProblemsEnvironment;
}

export interface UploadFileSettings {
    apiUrl: string;
    chunkSize: number;
    fileNameLengthLimit: number;
}

export interface TranslocoEnvironment {
    assetsPath: string;
    availableLanguages: AvailableLanguage[];
    reRenderOnLangChange: boolean;
    langUK: AvailableLanguageCode;
    langRU: AvailableLanguageCode;
    langEN?: AvailableLanguageCode;
    langPL?: AvailableLanguageCode;
}

export interface AvailableLanguage {
    code: AvailableLanguageCode;
    name: string;
    shortName: string;
    culture: Culture;
}

export enum AvailableLanguageCode {
    UK = 'uk',
    RU = 'ru',
    EN = 'en',
    PL = 'pl'
}

export enum Culture {
    UK = "uk-UA",
    RU = "ru-RU",
    EN = "en-US",
    PL = "pl-PL"
}

export interface EnvironmentLocalStorageNames {
    userProfile: string;
    token: string,
    graphqlScheme: string;
    locationHrefBeforeFacebookLogin: string;
    ticketStatuses: string;
    contactTypes: string;
}

export interface EnvironmentHttpHeaderNames {
    authorization: string;
}

export interface FindEnvironment {
    findUrl: string;
}

export interface ProblemsEnvironment {
    problemsUrl: string;
}
