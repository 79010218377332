import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {share} from "rxjs/operators";

import {environment} from "../../../environments/environment";
import {TokenStorageService} from "@app/shared/services/token-storage.service";
import {ProfileStorageService} from "@app/shared/services/profile-storage.service";
import {LoginResponseModel} from "@app/shared/models/login-response.model";
import {LoginRequestModel} from "@app/shared/models/login-request.model";
import {UserInfo} from "@app/shared/models/user-info.model";
import {SignalrService} from "@app/shared/services/signalr.service";


export const SCHEME_REGEXP: RegExp = /([A-za-z]*\s?) /;
export const DEFAULT_SCHEME: string = "Bearer";


@Injectable({
    providedIn: "root"
})
export class AuthService {

    constructor(private http: HttpClient,
                private tokenStorage: TokenStorageService,
                private profile: ProfileStorageService,
                private signalrService: SignalrService) {
    }


    login(authRequest: LoginRequestModel): Observable<LoginResponseModel> {
        return this.http.post<LoginResponseModel>(environment.auth.apiUrl, authRequest).pipe(share());
    }


    loginDomain(): Observable<LoginResponseModel> {
        return this.http.get<LoginResponseModel>(environment.auth.domainAuthUrl, {
            withCredentials: true
        }).pipe(share());
    }


    getUserInfo(): UserInfo {
        return {
            userId: this.profile.getUserId(),
            groupIds: this.profile.getGroupIds(),
            roles: this.profile.getRoles()
        };
    }


    getAuthToken(): string {
        const token: string = this.tokenStorage.token;
        return this.buildToken(token);
    }


    private buildToken(token: string): string {
        if (!token) return token;
        let scheme: string = DEFAULT_SCHEME;
        let schemes: RegExpMatchArray = token.match(SCHEME_REGEXP);

        if (schemes && schemes.length !== 0) {
            return token;
        }
        return `${scheme} ${token}`;
    }


    logout(): Observable<any> {
        this.signalrService.stopConnection();
        return this.http.post(environment.auth.logoutUrl, null).pipe(share());
    }


    checkAuthentication(): boolean {
        const token: string = this.getAuthToken();
        return Boolean(token);
    }

}
