import {Injectable} from "@angular/core";
import {HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpEvent} from '@angular/common/http';
import {NEVER, Observable} from "rxjs";
import {catchError} from "rxjs/operators";

import {Store} from '@ngrx/store';

import {environment} from "../../../environments/environment";
import {CleanAuthDataAndRedirect} from "@app/store/actions/auth.actions";
import {AuthService} from "@app/shared/services/auth.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
                private store: Store) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isAnonymousRoute(request.url)) {
            const authenticated: boolean = this.authService.checkAuthentication();

            if (!authenticated) {
                this.store.dispatch(CleanAuthDataAndRedirect());
                return NEVER;
            }
        }

        const token: string = this.authService.getAuthToken();
        if (!token) {
            return next.handle(request);
        }

        const facebookUrlSegment: string = 'facebook.com';

        if (!request?.url?.includes(facebookUrlSegment)) {
            request = request.clone({
                headers: request.headers.set('Authorization', token)
            });
        }

        return next.handle(request)
            .pipe(catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this.store.dispatch(CleanAuthDataAndRedirect());
                }
                return NEVER;
            }));
    }


    isAnonymousRoute(requestUrl: string): boolean {
        let anonymousServices: string[] = [environment.auth.apiUrl, environment.auth.domainAuthUrl];
        return anonymousServices.includes(requestUrl);
    }

}
