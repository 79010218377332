import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {defer, NEVER, Observable} from "rxjs";
import {finalize, share, tap} from "rxjs/operators";

import {SpinnerOverlayComponent} from "@app/shared/spinner-overlay/spinner-overlay.component";


@Injectable()
export class SpinnerOverlayService {

    private overlayRef: OverlayRef = undefined;


    constructor(private overlay: Overlay) {
    }


    readonly spinner$: Observable<never> = defer(() => {
        this.show();

        return NEVER.pipe(
            tap(() => {
                setTimeout(() => {
                    this.hide();
                }, 10000)
            }),
            finalize(() => {
                this.hide();
            })
        );
    }).pipe(share());


    show(): void {
        Promise.resolve(null).then(() => {
            this.overlayRef = this.overlay.create({
                positionStrategy: this.overlay
                    .position()
                    .global()
                    .centerHorizontally()
                    .centerVertically(),
                hasBackdrop: true,
            });
            this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
        });
    }


    hide(): void {
        this.overlayRef?.detach();
        this.overlayRef = undefined;
    }

}
