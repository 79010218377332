import {GqlError, IApiResponse} from "@app/shared/models/gql.response";

export class GqlExeption extends Error {
    originalResponse: IApiResponse<any>;

    constructor(private errors: GqlError[], ...params) {
        super(...params);

    }

    getError() {
        return this.errors.map(error => error.message).reduce((previousValue, currentValue) => {
            return `${previousValue} <br/>${currentValue}`;
        });
    }

    setOriginalResponse(response: IApiResponse<any>): void {
        this.originalResponse = response;
    }
}