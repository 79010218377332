import {PagingInfo} from "@app/_core/controls/table/models/page";


export class IApiResponse<T> {
    data: {
        string: T[] | any[]
    };
    errors: GqlError[];
    extensions: Extensions;
    pagination: Pagination;


    constructor(response: object) {
        Object.assign(this, response);
    }


    extractDataValueOrDefault<T>(propName: string, defaultValue: T): T {
        let data: any = this.getValueOrDefault<any>("data", null);

        if (data?.hasOwnProperty(propName)) {
            return data[propName];
        } else {
            return defaultValue;
        }
    }


    extractPaginationValueOrDefault<T>(propName: string, defaultValue: T): T {
        let data: any = this.getValueOrDefault<any>("pagination", null);

        if (data?.hasOwnProperty(propName)) {
            return data[propName];
        } else {
            return defaultValue;
        }
    }


    private getValueOrDefault<T>(propName: string, defaultValue: T): T {
        if (this?.hasOwnProperty(propName)) {
            return this[propName];
        } else {
            return defaultValue;
        }
    }

}


export interface Extensions {
    tracing: Tracing;
}


export interface Tracing {
    version: number;
    startTime: Date;
    endTime: Date;
    duration: number;
    parsing: Parsing;
    validation: Parsing;
    execution: Execution;
}


export interface Execution {
    resolvers: any[];
}


export interface Parsing {
    startOffset: number;
    duration: number;
}


export interface Pagination {
    [key: string]: PagingInfo
}


export interface GqlError {
    message: string;
    extensions: ErrorExtension;
}


export interface ErrorExtension {
    code: string;
    codes: string[];
    number: string;
}
