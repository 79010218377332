import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";

import {mutation, query} from "gql-query-builder";
import Fields from "gql-query-builder/build/Fields";
import {ToastrService} from "ngx-toastr";
import {TranslocoService} from "@ngneat/transloco";

import {environment} from "../../../environments/environment";
import {GqlExeption} from "@app/shared/exeptions/gql.exeption";
import {IApiResponse} from "@app/shared/models/gql.response";
import {ComparisonType} from "@app/_core/enums/comparison-type.enum";
import {GqlService} from "@app/_core/features/gql/services/gql.service";
import {Action} from "@app/_core/features/gql/enums/action.enums";
import {AbstractRepository, MutationDataType} from "@app/_core/interfaces/abstract-repository";
import {Filter, GetDataRequest, Paging, Sorting} from "@app/_core/interfaces/get-data-request";
import {_PreviewLevel} from "@app/models/types";


@Injectable({
    providedIn: "root"
})
export class GqlDataRepository implements AbstractRepository {

    constructor(private http: HttpClient,
                private toastr: ToastrService,
                private gqlService: GqlService,
                private translateService: TranslocoService) {
    }


    fetch<T>(config: GetDataRequest, customVariables?: Object, disableGlobalErrorCatch?: boolean): Observable<IApiResponse<T>> {
        let sortingConfig = this.applySortingToConfig(config.operation, config.sorting);
        let paginationConfig = this.applyPagingConfig(config.operation, config?.paging);
        let filterConfig = this.applyComplexFiltering(config.operation, config.filter);
        let gql = query({
            operation: config.operation,
            fields: config.columns,
            variables: {
                ...sortingConfig,
                ...paginationConfig,
                ...filterConfig,
                ...customVariables
            }
        });

        return this.executeQuery<T>(gql, disableGlobalErrorCatch);
    }


    getById<T>(operation: string, id: number, columns: Fields, disableGlobalErrorCatch?: boolean): Observable<T> {
        let filterConfig = this.gqlService.createFilterFromValue(ComparisonType.Equals, operation, "id", id);
        let gql = query({
            operation: operation,
            fields: columns,
            variables: {
                ...filterConfig
            }
        });

        return this.executeQuery<T>(gql, disableGlobalErrorCatch).pipe(
            map(response => this.getFirstOrDefault(response?.data, operation, null))
        );
    }


    mutate<T>(operation: string,
              data: MutationDataType,
              columns: Fields,
              action: Action,
              disableGlobalErrorCatch: boolean = false,
              previewLevel: _PreviewLevel = _PreviewLevel.Commit): Observable<T> {
        let itemConfig: any;

        if (data instanceof Array) {
            itemConfig = this.gqlService.createMutationConfig(operation, data, action);
        } else {
            itemConfig = this.gqlService.createMutationConfig(operation, [data], action);
        }

        let config: { operation: string, variables: any, fields: Fields } = {
            operation: operation,
            variables: {
                ...itemConfig
            },
            fields: columns || ["id"]
        };

        if (previewLevel !== _PreviewLevel.Commit) {
            config.variables.preview = previewLevel;
        }

        let gql: { variables: any, query: string } = mutation(config);

        if (gql.query && previewLevel !== _PreviewLevel.Commit) {
            gql.query = gql.query.replace('$preview: String', '$preview: [_PreviewLevel]');
        }

        return this.executeQuery<T>(gql, disableGlobalErrorCatch).pipe(
            map((response: IApiResponse<T>) => {
                if (!response) return null;
                return response || response.data[operation];
            }),
            map((response: any) => this.getFirstOrDefault(response?.data, operation, null))
        );
    }


    private getFirstOrDefault(obj: Object, propName: string, defaultValue: any) {
        if (obj?.hasOwnProperty(propName)) {
            let value: any = obj[propName];
            if (Array.isArray(value) && value.length > 0) {
                return value[0];
            }
        }
        return defaultValue;
    }


    executeQuery<T>(gql: {
        query: string,
        variables: object
    }, disableGlobalErrorCatch: boolean = false): Observable<IApiResponse<T>> {

        return this.http.post<IApiResponse<T>>(environment.baseUrl, gql, {
            params: {
                "disableGlobalErrorCatch": disableGlobalErrorCatch
            }
        }).pipe(tap(response => {
                if (response.errors) {
                    let exeption = new GqlExeption(response.errors);
                    exeption.setOriginalResponse(response);
                    throw exeption;
                }
            }),
            map(response => new IApiResponse(response)),
            catchError(error => {
                if (error instanceof GqlExeption) {
                    if (disableGlobalErrorCatch) {
                        return of(error.originalResponse);
                    }

                    // "Ошибка обработки запроса. Попробуйте чуть позже."
                    this.toastr.error(
                        (error as GqlExeption)?.getError(),
                        this.translateService.translate('repository.executeQuery.toastr.error.title')
                    );

                    return of(error.originalResponse);
                }
                return of(error);
            }));
    }


    private applySortingToConfig(operation: string, sorting: Sorting[] = []) {
        if (!sorting || sorting.length == 0) return null;
        let sortingConfig = {};
        sorting.forEach(sortItem => {
            if (!sortItem.sortDirection || !sortItem.sortColumn) return;
            let config = this.gqlService.generateSortingConfig(operation, sortItem.sortColumn, sortItem.sortDirection);
            Object.assign(sortingConfig, config);
        });
        return sortingConfig
    }


    private applyPagingConfig(operation: string, paging: Paging) {
        if (!paging?.pageNumber && !paging?.itemsPerPage) return null;
        return this.gqlService.generatePaginationConfig(operation, paging.pageNumber, paging.itemsPerPage);
    }


    private applyComplexFiltering(operation: string, filter: Filter) {
        if (!filter || Object.keys(filter).length === 0 || !operation) return null;
        let filterConfig = this.gqlService.createQueryFilter(operation, filter);
        return filterConfig;
    }


    private applySimpleFiltering(type: ComparisonType = ComparisonType.Equals, operation: string, column: string, value: any) {
        if (!column || !operation) return null;
        return this.gqlService.createFilterFromValue(type, operation, column, value);
    }
}
