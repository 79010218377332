import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {Store} from "@ngrx/store";
import {TranslocoService} from "@ngneat/transloco";

import {ApplicationSettingsService} from "@app/shared/services/application-settings.service";
import {CheckAuth} from "@app/store/actions/auth.actions";
import {LanguageService} from "@app/shared/services/language.service";
import {AvailableLanguageCode, Culture} from "../environments/environment.model";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    private destroy$: Subject<void> = new Subject();


    constructor(public appSettings: ApplicationSettingsService,
                private languageService: LanguageService,
                private store: Store,
                private title: Title,
                private translateService: TranslocoService) {
    }


    ngOnInit(): void {
        this.initialization();
    }


    private initialization(): void {
        this.languageService.getLanguage().pipe(takeUntil(this.destroy$)).subscribe(
            (culture: Culture) => {
                if (culture) {
                    const langCode: AvailableLanguageCode = culture.slice(0, 2) as AvailableLanguageCode;

                    this.translateService.load(langCode).subscribe(() => {
                        this.title.setTitle(this.appSettings.getApplicationName());
                        this.store.dispatch(CheckAuth());
                    });
                } else {
                    this.title.setTitle(this.appSettings.getApplicationName());
                    this.store.dispatch(CheckAuth());
                }
            }
        );
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
