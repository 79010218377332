import {Injectable} from '@angular/core';

import {environment} from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    get token(): string {
        return localStorage.getItem(environment.localStorageName.token);
    }

    set token(token: string) {
        localStorage.setItem(environment.localStorageName.token, token);
    }

    removeToken(): void {
        localStorage.removeItem(environment.localStorageName.token);
    }

}
