import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";

import {TranslocoService} from "@ngneat/transloco";
import {Store} from "@ngrx/store";
import Fields from "gql-query-builder/build/Fields";
import {ActiveToast} from "ngx-toastr/toastr/toastr.service";
import {ToastrService} from "ngx-toastr";

import {environment} from "../../../environments/environment";
import {AvailableLanguage, AvailableLanguageCode, Culture} from "../../../environments/environment.model";
import {GqlDataRepository} from "@app/_core/services/data.repository";
import {Logout} from "@app/store/actions/auth.actions";
import {UserInfo} from "@app/shared/models/user-info.model";
import {Action} from "@app/_core/features/gql/enums/action.enums";


@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    get availableLanguages(): AvailableLanguage[] {
        return environment.transloco.availableLanguages;
    }

    selectedLanguage: AvailableLanguage;
    previousLanguage: AvailableLanguage;

    currentUser: UserInfo;
    activeToast: ActiveToast<any>
    isLoading: boolean = false;


    constructor(private translateService: TranslocoService,
                private store: Store,
                private repository: GqlDataRepository,
                private toastr: ToastrService) {
        this.currentUser = this.getCurrentUserFromLocalStorage();
    }


    changeLanguage(language: AvailableLanguage): Observable<any> {
        const operation: string = "adminUnitUser";

        let inputData: any = {
            id: this.currentUser.userId,
            culture: language.culture
        };

        let fields: Fields = ['culture'];

        return this.repository.mutate<any>(operation, inputData, fields, Action.Update)
            .pipe(
                tap((response: any) => {
                    this.setSelectedLangByCode(language.code);
                    this.translateService.setActiveLang(language.code);

                    if (this.currentUser) {
                        this.currentUser.culture = language.culture;
                        this.setCurrentUserToLocalStorage(this.currentUser);
                    }

                    return response;
                })
            );
    }


    getLanguage(): Observable<Culture> {
        const operationName: string = 'adminUnitUser';
        const cultureName: string = 'culture';

        this.currentUser = this.getCurrentUserFromLocalStorage();

        if (this.currentUser) {
            if (this.currentUser.culture) {
                this.setSelectedLangByCulture(this.currentUser.culture);
                return of(this.currentUser.culture)
            }
        } else {
            this.logout();
            return of(null);
        }

        return this.repository.fetch<Observable<Culture>>({
            operation: operationName,
            columns: [cultureName],
            filter: {
                id: {
                    eq: this.currentUser.userId
                }
            }
        }).pipe(
            map((response: any) => {
                const cultureValue: Culture = response.data[operationName][0][cultureName];
                this.setSelectedLangByCulture(cultureValue);
                return cultureValue;
            })
        );
    }


    setSelectedLangByCode(langCode: AvailableLanguageCode): void {
        for (let i = 0; i < this.availableLanguages.length; i++) {
            if (this.availableLanguages[i].code === langCode) {
                this.selectedLanguage = this.availableLanguages[i];
                this.translateService.setActiveLang(this.selectedLanguage.code);
                break;
            }
        }
    }


    setSelectedLangByCulture(culture: Culture): void {
        for (let i = 0; i < this.availableLanguages.length; i++) {
            if (this.availableLanguages[i].culture === culture) {
                this.selectedLanguage = this.availableLanguages[i];
                this.previousLanguage = this.availableLanguages[i];
                this.translateService.setActiveLang(this.selectedLanguage.code);
                break;
            }
        }
    }


    getCurrentUserFromLocalStorage(): UserInfo | null {
        const currentUserProfile: string | undefined = localStorage.getItem(environment.localStorageName.userProfile);

        if (currentUserProfile) {
            return JSON.parse(currentUserProfile);
        }

        return null;
    }


    setCurrentUserToLocalStorage(currentUser: UserInfo): void {
        localStorage.setItem(environment.localStorageName.userProfile, JSON.stringify(currentUser));
    }


    logout(): void {
        this.store.dispatch(Logout());
    }


    removeActiveToast(): void {
        if (this.activeToast) {
            this.toastr.remove(this.activeToast.toastId);
            this.activeToast.toastId = null;
        }
    }

}
