import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {EMPTY, of} from "rxjs";

import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslocoService} from "@ngneat/transloco";

import {environment} from "../../../environments/environment";
import {
    CheckAuth, LogIn, LogInDomain, LoginFailure,
    LogInSuccess, CleanAuthDataAndRedirect, Logout
} from "../actions/auth.actions";
import {AuthService} from "@app/shared/services/auth.service";
import {ProfileStorageService} from "@app/shared/services/profile-storage.service";
import {SpinnerOverlayService} from "@app/shared/services/spinner-overlay.service";
import {TokenStorageService} from "@app/shared/services/token-storage.service";
import {GraphqlSchemaUtils} from "@app/_core/utils/graphql-schema.utils";
import {LoginResponseModel} from "@app/shared/models/login-response.model";
import {LanguageService} from "@app/shared/services/language.service";
import {AvailableLanguageCode, Culture} from "../../../environments/environment.model";
import {FacebookLoginService} from "@app/shared/services/facebook-login.service";


const AUTH_PATHS: string[] = ["/auth"];


@Injectable()
export class AuthEffects {

    loginDomain$ = createEffect(() => this.actions$.pipe(
        ofType(LogInDomain),
        switchMap(() => {
            return this.authService.loginDomain().pipe(
                map(result => {
                    return LogInSuccess(result);
                }),
                catchError(exeption => {
                    return of(LoginFailure({
                        errors: [exeption.statusText]
                    }))
                })
            )
        })
    ));


    login$ = createEffect(() => this.actions$.pipe(
        ofType(LogIn),
        switchMap(payload => {
            return this.authService.login(payload).pipe(
                map(result => {
                    return LogInSuccess(result);
                }),
                catchError(exeption => {
                    return of(LoginFailure({
                        errors: [exeption.statusText]
                    }))
                })
            )
        })
    ));


    loginSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(LogInSuccess),
        tap((response: LoginResponseModel) => {
            let redirectUrl: string = this.activatedRoute?.snapshot?.queryParamMap?.get("redirectUrl") ?? "/";

            this.tokenStorage.token = response.data.token;

            this.profileStorage.profile = {
                ...response.data
            };

            this.languageService.getLanguage().subscribe(
                (culture: Culture) => {
                    if (culture) {
                        const langCode: AvailableLanguageCode = culture.slice(0, 2) as AvailableLanguageCode;
                        this.translateService.load(langCode).subscribe(() => {
                            this.translateService.setActiveLang(langCode);
                            this.router.navigate([redirectUrl]);
                        });
                    }
                });
        })
    ), {
        dispatch: false
    });


    cleanAuthDataAndRedirect$ = createEffect(() => this.actions$.pipe(
        ofType(CleanAuthDataAndRedirect),
        tap(_ => {

            let params: Params = {
                "redirectUrl": AUTH_PATHS.includes((location.pathname)) ? "/" : location.pathname
            };
            this.tokenStorage.removeToken();
            this.profileStorage.removeProfile();
            this.facebookLoginService.removeFacebookData();

            localStorage.removeItem(environment.localStorageName.ticketStatuses);
            localStorage.removeItem(environment.localStorageName.contactTypes);

            GraphqlSchemaUtils.deleteSchemaFromLocalstorage();

            this.router.navigate(AUTH_PATHS, {
                queryParams: params
            })
                .then(_ => {
                    this.spinnerOverlayService.hide();
                });
        })
    ), {
        dispatch: false
    });


    logout$ = createEffect(() => this.actions$.pipe(
            ofType(Logout),
            switchMap(_ => {
                return this.authService.logout().pipe(
                    map(_ => CleanAuthDataAndRedirect()),
                    catchError(error => EMPTY))
            })
        )
    )


    checkAuth$ = createEffect(() => this.actions$.pipe(
        ofType(CheckAuth),
        tap(_ => {
                let authenticated = this.authService.checkAuthentication();
                if (!authenticated) {
                    this.store.dispatch(CleanAuthDataAndRedirect());
                }
            }
        )
    ), {
        dispatch: false
    });


    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private translateService: TranslocoService,
                private store: Store,
                private actions$: Actions,
                private authService: AuthService,
                private profileStorage: ProfileStorageService,
                private tokenStorage: TokenStorageService,
                private spinnerOverlayService: SpinnerOverlayService,
                private languageService: LanguageService,
                private facebookLoginService: FacebookLoginService) {
    }

}
