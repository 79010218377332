import {IntrospectionSchema} from "graphql/utilities/getIntrospectionQuery";

export class GqlIntrospectionOptions {
    private readonly schema?: IntrospectionSchema
    private readonly schemaFactory: () => IntrospectionSchema;


    constructor(config: {
        schema?: IntrospectionSchema,
        schemaFactory?: () => IntrospectionSchema
    }) {
        if (config && config.schema) {
            this.schema = config.schema;
        }
        if (config && config.schemaFactory) {
            this.schemaFactory = config.schemaFactory;
        }
    }


    getSchema(): IntrospectionSchema {
        if (this.schema) return this.schema;
        if (this.schemaFactory) return this.schemaFactory();
        return null;
    }
}
