import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Environment} from "../../../environments/environment.model";

@Injectable({
    providedIn: 'root'
})
export class ApplicationSettingsService {

    constructor() {
    }


    getSettings(): Environment {
        return environment;
    }

    getApplicationName(): string {
        return environment.appName;
    }
}
