export enum ComparisonType {
    GreateThen = "gt",
    GreateOrEqual = "gte",
    LessThen = "lt",
    LessOrEqual = "lte",
    In = "in",
    NotIn = "notIn",
    Equals = "eq",
    NotEqual = "notEq",
    IsNull = "isNull",
    IsNotNull = "isNotNull",
    Any = "any",
    All = "all",
    NotAny = "notAny",
    NotAll = "notAll",
    Contains = "contains",
    StartsWith = "startsWith",
    endsWith = "endsWith"
}