import {
    ComponentFactory, Injectable, Injector, NgModuleFactory, Compiler, ComponentRef, Inject
} from "@angular/core";

import {COMPONENT_MANIFESTS} from "@app/_core/features/component-loader/tokens/manifest-token";
import {DynamicModuleBase} from "@app/_core/features/component-loader/models/dynamic-module-base";


@Injectable({
    providedIn: "root"
})
export class DynamicComponentService {

    constructor(private injector: Injector,
                @Inject(COMPONENT_MANIFESTS) private manifests: any[]) {
    }


    getComponentBySelector(
        componentSelector: string
    ): Promise<ComponentRef<unknown>> {
        let moduleResolver = this.getModuleFactoryResolver(componentSelector);
        return this.getModuleFactory(moduleResolver).then(moduleFactory => {
            return this.createComponent(moduleFactory, componentSelector);
        });
    }


    private getModuleFactoryResolver(componentSelector: string): () => Promise<any> {
        let manifest = this.manifests
            .reduce(m => m)
            .find(m => m.selector === componentSelector);

        return manifest?.moduleLoader || Promise.resolve;
    }


    private createComponent(moduleFactory, componentSelector: string) {
        const module = moduleFactory.create(this.injector);
        if (module.instance instanceof DynamicModuleBase) {
            const compFactory: ComponentFactory<any> = module.instance.getComponentFactory(componentSelector);
            return compFactory.create(module.injector, [], null, module);
        } else {
            throw new Error('Module should extend BaseModule to use "string" based component selector');
        }
    }


    async getModuleFactory(moduleLoaderFunction: () => Promise<NgModuleFactory<any>>) {
        const ngModuleOrNgModuleFactory = await moduleLoaderFunction();
        let moduleFactory;
        if (ngModuleOrNgModuleFactory instanceof NgModuleFactory) {
            moduleFactory = ngModuleOrNgModuleFactory;
        } else {
            moduleFactory = await this.injector
                .get(Compiler)
                .compileModuleAsync(ngModuleOrNgModuleFactory);
        }
        return moduleFactory;
    }

}
