import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";

import {AuthGuard} from "@app/shared/guards/auth.guard";
import {GraphqlSchemeResolver} from "@app/shared/resolvers/graphql-scheme.resolver";


const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "app"
    },
    {
        path: "facebook-callback",
        pathMatch: "full",
        redirectTo: "app/facebook-callback"
    },
    {
        path: "app",
        loadChildren: () => import("./nano-crm/nano-crm.module").then(m => m.NanoCrmModule),
        canActivate: [AuthGuard],
        resolve: {
            graphqlScheme: GraphqlSchemeResolver
        }
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    }
];


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
