import {AvailableLanguageCode, Culture, Environment} from "./environment.model";

export const environment: Environment = {
    production: true,
    appName: "RozDesk",
    supportEmail: "support@rozetka.com.ua",
    baseUrl: "/api/graphql",
    uploadFileSettings: {
        apiUrl: "api/file",
        chunkSize: 1024 * 1024 * 5,
        fileNameLengthLimit: 40
    },
    auth: {
        apiUrl: "api/auth/login",
        domainAuthUrl: "api/auth/ntlm",
        logoutUrl: "api/auth/logout"
    },
    getFullEmailMessageUrl: "data/comments/",
    invalidTicketIdUrl: "/app/invalid-ticket-id",
    signalrUrl: "/messageHub",
    oldSearchUrl: "app/search",
    transloco: {
        assetsPath: 'assets/i18n',
        availableLanguages: [
            {
                code: AvailableLanguageCode.UK,
                name: 'Українська',
                shortName: 'UK',
                culture: Culture.UK
            },
            {
                code: AvailableLanguageCode.RU,
                name: 'Русский',
                shortName: 'RU',
                culture: Culture.RU
            },
            {
                code: AvailableLanguageCode.PL,
                name: 'Polska',
                shortName: 'PL',
                culture: Culture.PL
            }
            // {
            //     code: AvailableLanguageCode.EN,
            //     name: 'English',
            //     shortName: 'EN',
            //     culture: Culture.EN
            // }
        ],
        reRenderOnLangChange: false,  // 'true' if application support changing language in runtime
        langUK: AvailableLanguageCode.UK,
        langRU: AvailableLanguageCode.RU,
        langPL: AvailableLanguageCode.PL,
        // langEN: AvailableLanguageCode.EN
    },
    localStorageName: {
        userProfile: '_profile',
        token: '_token',
        graphqlScheme: 'graphql-scheme',
        locationHrefBeforeFacebookLogin: 'locationHrefBeforeFacebookLogin',
        ticketStatuses: 'ticketStatuses',
        contactTypes: 'contactTypes'
    },
    httpHeaderName: {
        authorization: 'Authorization'
    },
    emojiForServiceTicketTitle: '🐥',
    find: {
        findUrl: 'find'
    },
    problems: {
        problemsUrl: 'problems'
    }
};
