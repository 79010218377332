import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route, Router,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {map, mapTo, tap} from "rxjs/operators";
import {AuthState} from "@app/store";
import {getLoggenIn} from "@app/store/selectors/auth.selector";
import {CleanAuthDataAndRedirect} from "@app/store/actions/auth.actions";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private store: Store<AuthState>, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(getLoggenIn).pipe(
            tap(loggedIn => {
                if (!loggedIn) this.router.navigateByUrl("/auth");
            }),
            map(loggedIn => {
                return !!loggedIn
            }));
    }

    canLoad(route: Route, segments: UrlSegment[]) {
        return this.store.select(getLoggenIn).pipe(
            tap(loggedIn => {
                if (!loggedIn) this.router.navigateByUrl("/auth");
            }),
            map(loggedIn => {
                return !!loggedIn
            }));
    }

}
