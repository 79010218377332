import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, tap} from "rxjs/operators";

import {GraphqlSchemaUtils} from "@app/_core/utils/graphql-schema.utils";
import {ApplicationSettingsService} from "@app/shared/services/application-settings.service";
import {IntrospectionSchema} from "graphql/utilities/getIntrospectionQuery";


const SCHEME_QUERY = `query IntrospectionQuery {
      __schema {
        queryType { name }
        mutationType { name }
        subscriptionType { name }
        types {
          ...FullType
        }
        directives {
          name
          description
          
          locations
          args {
            ...InputValue
          }
        }
      }
    }

    fragment FullType on __Type {
      kind
      name
      description
      
      fields(includeDeprecated: true) {
        name
        description
        args {
          ...InputValue
        }
        type {
          ...TypeRef
        }
        isDeprecated
        deprecationReason
      }
      inputFields {
        ...InputValue
      }
      interfaces {
        ...TypeRef
      }
      enumValues(includeDeprecated: true) {
        name
        description
        isDeprecated
        deprecationReason
      }
      possibleTypes {
        ...TypeRef
      }
    }

    fragment InputValue on __InputValue {
      name
      description
      type { ...TypeRef }
      defaultValue
      
      
    }

    fragment TypeRef on __Type {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                  }
                }
              }
            }
          }
        }
      }
    }`;


@Injectable({
    providedIn: 'root'
})
export class GraphqlSchemeResolver implements Resolve<IntrospectionSchema> {

    constructor(private httpClient: HttpClient,
                private appSettings: ApplicationSettingsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IntrospectionSchema> {
        let localScheme = GraphqlSchemaUtils.getSchemaFromLocalstorage();
        if (localScheme && !GraphqlSchemaUtils.isExpired()) {
            return of(localScheme);
        }

        let settings = this.appSettings.getSettings();
        return this.httpClient.post<any>(settings.baseUrl, {
            query: SCHEME_QUERY,
            variables: {}
        }).pipe(
            tap(res => {
                GraphqlSchemaUtils.saveSchemaInLocalstorage(res.data);
            }),
            map(response => GraphqlSchemaUtils.getSchemaFromLocalstorage()))
    }

}
