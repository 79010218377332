import {ActionReducer, INIT, MetaReducer, UPDATE} from "@ngrx/store";
import {State} from "@app/store";


export const partialStateSyncReducers: (keys: Array<string>) => Array<MetaReducer<State>> = function (keys): Array<MetaReducer<State>> {
    let metaReducers: Array<MetaReducer<State>> = [];

    keys.forEach(key => {
        let metaReducer = (
            reducer: ActionReducer<State>
        ): ActionReducer<State> => {
            return (state, action) => {
                if (action.type === INIT || action.type === UPDATE) {
                    const storageValue = localStorage.getItem(key);
                    if (storageValue) {
                        try {
                            let value = {};
                            value[key] = JSON.parse(storageValue);
                            let savedState = {
                                ...state ?? {}, ...value
                            };
                            return savedState as State;
                        } catch {
                            localStorage.removeItem(key);
                        }
                    }
                }
                const nextState = reducer(state, action);
                localStorage.setItem("auth", JSON.stringify(nextState[key]));
                return nextState;
            };
        };

        metaReducers.push(metaReducer);
    });
    return metaReducers;
}
