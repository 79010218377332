import {Action, createReducer, on} from "@ngrx/store";
import {LogIn, LoginFailure, LogInSuccess, CleanAuthDataAndRedirect} from "@app/store/actions/auth.actions";
import {AuthState} from "@app/store";


export const initialState: AuthState = {
    isLoggedIn: false,
    token: null,
    errors: null,
    userName: null
};


export const authReducer = createReducer(initialState,
    on(LogIn, (state) => {
        return {
            ...state
        }
    }),
    on(LogInSuccess, (state, payload) => {
        return {
            ...state,
            isLoggedIn: true,
            userName: payload.data.login,
            token: payload.data.token
        }
    }),
    on(LoginFailure, (state, payload) => {
        return {
            ...state,
            isLoggedIn: false,
            errors: payload.errors,
            token: null,
            userName: null
        }
    }),
    on(CleanAuthDataAndRedirect, (state) => {
        return {
            ...initialState
        };
    }));


export function reducer(state: AuthState, action: Action) {
    return authReducer(state, action);
}