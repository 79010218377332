import {Injectable} from '@angular/core';

import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {HubConnectionState} from "@microsoft/signalr";
import {ToastrService} from "ngx-toastr";
import {TranslocoService} from "@ngneat/transloco";

import {environment} from "../../../environments/environment";
import {TokenStorageService} from "@app/shared/services/token-storage.service";
import {UserFeaturesEnum} from "@app/nano-crm/components/tickets/types/user-features.enum";
import {ProfileStorageService} from "@app/shared/services/profile-storage.service";


@Injectable({
    providedIn: 'root'
})
export class SignalrService {

    hubConnection: HubConnection;

    beforeUnloadListener: EventListener;


    constructor(private toastr: ToastrService,
                private translateService: TranslocoService,
                private tokenStorage: TokenStorageService,
                private profileStorageService: ProfileStorageService) {
    }


    createNewHubConnection(): void {
        if (!this.hubConnection) {
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(environment.signalrUrl, {
                    accessTokenFactory: () => this.tokenStorage.token,
                    logger: LogLevel.None
                })
                .build();
        }
    }


    // result: void
    applicationStoppingHandler(): void {
        if (this.hubConnection && this.isUserFeatureAllowedSignalR()) {
            this.hubConnection.on('applicationStopping', () => {
                // "The application stopped!"
                this.showApplicationStoppingMessage();
            });
        }
    }


    // result: {message: "", messageFromId: 0}
    messageHandler(): void {
        if (this.hubConnection && this.isUserFeatureAllowedSignalR()) {
            this.hubConnection.on('message', (data: { message: string, messageFromId: number }) => {
                this.showMessageWarningMessage(data);
            });
        }
    }


    startConnection(): void {
        if (this.hubConnection
            && this.hubConnection.state === HubConnectionState.Disconnected
            && this.isUserFeatureAllowedSignalR()) {
            this.hubConnection.start()
                .catch(() => {
                    this.translateService.selectTranslateObject('signalR.message')
                        .subscribe(() => {
                            // "Error connecting to SignalR"
                            console.error(this.translateService.translate('signalR.message.hubConnectionStart.message'));
                        });
                });
        }
    }


    stopConnection(): void {
        if (this.hubConnection) {
            this.hubConnection.stop()
                .catch(() => {
                    this.translateService.selectTranslateObject('signalR.message')
                        .subscribe(() => {
                            // "Failed to close SignalR connection"
                            console.error(this.translateService.translate('signalR.message.hubConnectionStop.message'));
                        });

                });
        }
    }


    isUserFeatureAllowedSignalR(role: UserFeaturesEnum = UserFeaturesEnum.FR_18_SignalRAccess): boolean {
        return this.profileStorageService.checkRole(role);
    }


    private showApplicationStoppingMessage(): void {
        this.translateService.selectTranslateObject('signalR.message')
            .subscribe(() => {
                const title: string = this.translateService.translate('signalR.message.applicationStopping.title');
                const message: string = this.translateService.translate('signalR.message.applicationStopping.message');
                this.toastr.warning(message, title);
            });
    }


    private showMessageWarningMessage(data: { message: string, messageFromId: number }): void {
        this.translateService.selectTranslateObject('signalR.message')
            .subscribe(() => {
                const title: string = this.translateService.translate('signalR.message.warningMessage.title');
                let message: string = this.translateService.translate('signalR.message.warningMessage.emptyMessage');

                if (data?.message?.length > 0) {
                    message = this.translateService.translate('signalR.message.warningMessage.message')
                        + ' ' + data.message;
                }

                this.toastr.warning(message, title);
            });
    }

}
