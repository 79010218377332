import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {ToastrModule} from "ngx-toastr";

import {environment} from '../environments/environment';
import {GraphqlSchemaUtils} from "@app/_core/utils/graphql-schema.utils";
import {SpinnerOverlayService} from "@app/shared/services/spinner-overlay.service";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {AuthInterceptor} from "@app/shared/interceptors/auth.interceptor";
import {reducers, State} from "@app/store";
import {ErrorInterceptor} from "@app/shared/interceptors/error.interceptor";
import {AuthEffects} from "@app/store/effects/auth.effects";
import {MaterialModule} from "@app/_core/material.module";
import {partialStateSyncReducers} from "@app/store/reducers/meta.reducer";
import {ComponentLoaderModule} from "@app/_core/features/component-loader/component-loader.module";
import {GqlModule} from "@app/_core/features/gql/gql.module";
import {TranslocoRootModule} from './transloco-module/transloco-root.module';


export const metaReducers: MetaReducer<State>[] = partialStateSyncReducers(["auth"]);


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        MaterialModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, {
            metaReducers: metaReducers
        }),
        EffectsModule.forRoot([AuthEffects]),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        StoreRouterConnectingModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            enableHtml: true
        }),
        GqlModule.forRoot({
            schemaFactory: () => {
                return GraphqlSchemaUtils.getSchemaFromLocalstorage();
            }
        }),
        ComponentLoaderModule.forRoot([
            {
                selector: "cti-panel",
                moduleLoader: () => import("@app/widgets/widgets.module").then(m => m.WidgetModule)
            }
        ]),
        TranslocoRootModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        SpinnerOverlayService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
