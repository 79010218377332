import {Injectable} from "@angular/core";

import {GraphQLFieldMap, GraphQLScalarType} from "graphql/type/definition";
import {GqlIntrospection} from "./gql-introspection";
import {ComparisonType} from "@app/_core/enums/comparison-type.enum";
import {Action} from "@app/_core/features/gql/enums/action.enums";
import {Filter} from "@app/_core/interfaces/get-data-request";


const DEFAULT_MUTATION_COLUMNS = {
    "id": {
        name: "id",
        type: new GraphQLScalarType({
            name: "Int"
        }),
        description: "Идентификатор",
        args: [],
        isDeprecated: false,
        deprecationReason: null,
        extensions: null
    },
    "code": {
        name: "code",
        type: new GraphQLScalarType({
            name: "String"
        }),
        description: "Код",
        args: [],
        isDeprecated: false,
        deprecationReason: null,
        extensions: null
    }
};


@Injectable({
    providedIn: "root"
})
export class GqlService {

    constructor(private introspection: GqlIntrospection) {
    }


    generatePaginationConfig(operation: string, pageNumber: number, itemsPerPage: number): {
        [key: string]: { value: any, type: string }
    } {
        let itemsPerPageArg = this.introspection.getQueryArg(operation, "itemsPerPage");
        let pageArg = this.introspection.getQueryArg(operation, "page");
        const config = {
            [itemsPerPageArg.name]: {
                value: itemsPerPage || Number.parseInt(itemsPerPageArg.defaultValue),
                type: itemsPerPageArg.type.inspect()
            },
            [pageArg.name]: {
                value: pageNumber || Number.parseInt(pageArg.defaultValue),
                type: pageArg.type.inspect()
            }
        };

        return config;
    }


    generateSortingConfig(operation: string, columnName: string = undefined, sortDirection: string = undefined): {
        [key: string]: { value: any, type: string }
    } {

        let config = {};
        if (this.introspection.checkColumnSortable(operation, columnName)) {
            let argDef = this.introspection.getSortingDef(operation);
            config = {
                [argDef.name]: {
                    value: [{
                        [columnName]: this.mapSortDirection(sortDirection)
                    }],
                    type: argDef.type
                }
            }
        }
        return config;
    }


    createMutationConfig(operation: string, data: Array<any>, action: Action): any {
        let mutationArgs = this.introspection.getMutationArgs(operation);
        let mutationDef = mutationArgs.find(i => i.name === "items");
        let mappedDataForMutation = data.map(item => this.mapMutationData(operation, item, action));
        let config = {
            [mutationDef.name]: {
                value: mappedDataForMutation,
                type: mutationDef.type.inspect()
            }
        };
        return config;
    }


    private mapMutationData(operation, data: any, action?: Action) {
        let mutationFields = this.getMutationFields(operation);
        let dataForMutation = {} as any;
        if (action) {
            dataForMutation.action = action;
        }
        for (const dataKey in data) {
            let field = mutationFields[dataKey];
            if (!field) continue;
            if (this.introspection.isScalarType(field?.type)
                || this.isArrayOfScalar(field?.type)
                || this.isNullOrEmpty(data[dataKey])) {
                dataForMutation[dataKey] = data[dataKey];
            } else {
                if (this.isArrayOfComplexType(field?.type)) {
                    let mutationData = [];
                    if (data[dataKey] instanceof Array) {
                        mutationData = (data[dataKey] as Array<any>).map(d => d["id"])
                        //mutationData = (data[dataKey] as Array<any>).map(d => {
                        //    let fType = (field?.type && (field?.type as any).ofType) || field?.type;
                        //    return this.mapMutationData(this.getOperationName((fType as any)?.name), d)
                        //})
                    } else {
                        mutationData = [{
                            id: data[dataKey].id
                        }]
                    }

                    dataForMutation[dataKey] = mutationData;
                    continue;
                }
                if (this.isEnumType(field?.type)) {
                    dataForMutation[dataKey] = data[dataKey];
                    continue;
                }
                if (this.isInputObjectType(field?.type)) {
                    dataForMutation[dataKey] = this.mapMutationData(this.getOperationName((field?.type as any)?.name),
                        data[dataKey]);
                }
            }
        }
        return dataForMutation;
    }


    private getMutationFields(operation: string): GraphQLFieldMap<any, any> {
        let mutationFields = this.introspection.getMutationFields(operation);
        if (!mutationFields) {
            mutationFields = {
                ...DEFAULT_MUTATION_COLUMNS
            }
        }
        return mutationFields;
    }


    private getOperationName(mutationName: string) {
        let operation = mutationName.replace("Mutation", "");
        let firstLetter = operation[0].toLowerCase();
        operation = firstLetter + operation.substring(1, operation.length);
        return operation;
    }


    isArrayOfScalar(type): boolean {
        return this.introspection.isArrayType(type) && this.introspection.isScalarType(type.ofType);
    }


    isArrayOfComplexType(type): boolean {
        return this.introspection.isArrayType(type) && !this.introspection.isScalarType(type.ofType);
    }


    isInputObjectType(type): boolean {
        return this.introspection.isInputObjectType(type);
    }


    isEnumType(type): boolean {
        return this.introspection.isEnumType(type);
    }


    createFilterFromValue(filterType: ComparisonType, operation: string, column: string, value: any) {
        let filterArg = this.introspection.getQueryArg(operation, "filter");
        let filterArgDef = this.introspection.getArgDefinition(filterArg);
        let config = {
            filter: {
                type: filterArgDef.type,
                value: {
                    [column]: {
                        [filterType]: value
                    }
                }
            }
        }
        return config;
    }

    createQueryFilter(operation: string, filter: Filter) {
        let filterArg = this.introspection.getQueryArg(operation, "filter");
        let filterArgDef = this.introspection.getArgDefinition(filterArg);
        let config = {
            filter: {
                type: filterArgDef.type,
                value: filter
            }
        }
        return config;
    }


    private mapSortDirection(sortMode: string): string {
        switch (sortMode) {
            case "desc":
                return "Desc";
            default:
                return "Asc"
        }
    }


    isNullOrEmpty(value) {
        return !value
    }

}
