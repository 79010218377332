import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

import {MatPaginatorIntl} from "@angular/material/paginator";
import {TranslocoService} from "@ngneat/transloco";


@Injectable()
export class TranslocoCustomPaginator implements MatPaginatorIntl {

    changes: Subject<void> = new Subject<void>();

    firstPageLabel: string = this.translateService.translate('paginator.firstPageLabel');
    lastPageLabel: string = this.translateService.translate('paginator.lastPageLabel');
    nextPageLabel: string = this.translateService.translate('paginator.nextPageLabel');
    previousPageLabel: string = this.translateService.translate('paginator.previousPageLabel');
    itemsPerPageLabel: string = this.translateService.translate('paginator.itemsPerPageLabel');


    constructor(private translateService: TranslocoService) {
    }


    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            // return `Page 1 of 1`;
            return this.translateService.translate('paginator.page1of1');
        }
        const currentPage: number = page + 1;
        const amountPages: number = Math.ceil(length / pageSize);

        // `Page {{ currentPage}} of {{ amountPages }}`;
        return this.translateService.translate('paginator.currentPageOfAmountPages', {currentPage, amountPages});
    }

}
