import {ModuleWithProviders, NgModule} from "@angular/core";

import {IntrospectionSchema} from "graphql/utilities/getIntrospectionQuery";
import {SCHEMA} from "./schemas";
import {GqlIntrospection, INTROSPECTION_TOKEN} from "@app/_core/features/gql/services/gql-introspection";
import {GqlService} from "@app/_core/features/gql/services/gql.service";
import {GqlIntrospectionOptions} from "@app/_core/features/gql/models/introspection-options.model";


@NgModule({
    providers: [
        {
            provide: GqlIntrospection
        },
        {
            provide: GqlService
        },
        {
            provide: INTROSPECTION_TOKEN,
            useValue: {
                schema: SCHEMA.__schema
            }
        }
    ]
})
export class GqlModule {
    public static forRoot(config: {
        schema?: IntrospectionSchema,
        schemaFactory?: () => IntrospectionSchema
    }): ModuleWithProviders<GqlModule> {
        return {
            ngModule: GqlModule,
            providers: [
                {
                    provide: GqlIntrospection
                },
                {
                    provide: GqlService
                },
                {
                    provide: INTROSPECTION_TOKEN,
                    useFactory: (): GqlIntrospectionOptions => {
                        if (config.schema) {
                            return new GqlIntrospectionOptions({
                                schema: config.schema
                            })
                        }
                        if (config.schemaFactory) {
                            return new GqlIntrospectionOptions({
                                schemaFactory: () => {
                                    let schema = config.schemaFactory();
                                    if (schema) return schema;
                                    return SCHEMA.__schema;
                                }
                            })

                        }

                        return new GqlIntrospectionOptions({
                            schema: SCHEMA.__schema
                        });
                    }
                }]
        }
    }
}
