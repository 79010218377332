import {Injectable} from '@angular/core';
import {of} from "rxjs";
import {Observable} from "rxjs/internal/Observable";

import {environment} from "../../../environments/environment";
import {UserInfoSettings} from "@app/shared/models/user-info-settings.type";
import {UserInfo} from "@app/shared/models/user-info.model";


@Injectable({
    providedIn: 'root'
})
export class ProfileStorageService {

    private key: string = environment.localStorageName.userProfile;

    get profile(): Observable<UserInfo> {
        return of(JSON.parse(localStorage.getItem(this.key)));
    }

    set profile(profile: any) {
        localStorage.setItem(this.key, JSON.stringify(profile));
    }

    get currentUser(): UserInfo {
        return JSON.parse(localStorage.getItem(this.key));
    }

    get userSettings(): UserInfoSettings {
        return (JSON.parse(localStorage.getItem(this.key)))?.settings;
    }


    removeProfile(): void {
        localStorage.removeItem(this.key);
    }


    checkRole(role: string): boolean {
        let roles: string[] = this.getRoles();
        return roles ? roles.includes(role) : false;
    }


    getRoles(): string[] {
        return JSON.parse(localStorage.getItem(this.key))?.roles as [];
    }


    getUserId(): number {
        let userId = JSON.parse(localStorage.getItem(this.key))?.userId;
        if (!userId) return userId;
        return parseInt(userId);
    }


    getGroupIds(): string[] {
        return JSON.parse(localStorage.getItem(this.key))?.groupIds as [];
    }

}
