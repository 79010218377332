import {Inject, Injectable, InjectionToken} from "@angular/core";

import Fields from "gql-query-builder/build/Fields";
import {
    buildClientSchema,
    getNamedType,
    GraphQLArgument,
    GraphQLField, GraphQLInputType,
    GraphQLObjectType,
    GraphQLSchema, GraphQLType,
    isCompositeType, isNonNullType, isObjectType, isScalarType
} from "graphql";

import {GraphQLFieldMap, isEnumType, isInputObjectType, isListType, isNullableType} from "graphql/type/definition";
import {GqlIntrospectionOptions} from "@app/_core/features/gql/models/introspection-options.model";
import {FieldDefinition, Query} from "@app/_core/features/gql/models/grid-column-def.model";


export const INTROSPECTION_TOKEN = new InjectionToken<GqlIntrospectionOptions>("INTROSPECTION_TOKEN");

@Injectable({
    providedIn: "root"
})
export class GqlIntrospection {

    _schema: GraphQLSchema = null;
    get schema(): GraphQLSchema {
        if (!this._schema) {
            let schema = this.options.getSchema();
            this._schema = buildClientSchema({
                __schema: schema
            });
        }
        return this._schema;
    }

    set schema(value) {
        this._schema = value;
    }

    constructor(@Inject(INTROSPECTION_TOKEN) private options: GqlIntrospectionOptions) {

    }


    getSchema(): GraphQLSchema {
        try {
            return this.schema //(this.schema as GqlSchema).__schema;
        } catch (error) {
            return null;
        }
    }


    getQueries(): GraphQLObjectType {
        return this.schema.getQueryType();
    }

    getQuery(queryName: string): GraphQLField<any, any> {
        return this.getQueries()?.getFields()[queryName];
    }

    getMutations(): GraphQLObjectType {
        return this.schema.getMutationType();
    }

    getMutation(mutationName: string): GraphQLField<any, any> {
        return this.getMutations()?.getFields()[mutationName];
    }

    getMutationArgs(mutationName: string): GraphQLArgument[] {
        let query = this.getMutation(mutationName);
        return query?.args;
    }


    getQueryArgs(queryName: string): GraphQLArgument[] {
        let query = this.getQuery(queryName);
        return query.args;
    }

    getMutationFields(mutationName: string): GraphQLFieldMap<any, any> {
        let arg = this.getMutationArg(mutationName, "items");
        return this.getArgFields(arg);
    }

    private getArgFields(arg: GraphQLArgument, type: GraphQLInputType = null): GraphQLFieldMap<any, any> {
        if (!arg) return null;
        let inputType = type;
        if (inputType == null) {
            inputType = arg?.type['ofType'];
        }

        if (inputType["getFields"]) {
            return inputType["getFields"]();
        } else {
            return this.getArgFields(arg, inputType["ofType"]);
        }
    }

    getMutationArg(mutationName: string, argName: string): GraphQLArgument {
        let args = this.getMutationArgs(mutationName);
        return args?.find(i => i.name === argName);
    }

    isScalarType(type: any): boolean {
        return isScalarType(type);
    }

    isArrayType(type: any): boolean {
        return isListType(type);
    }

    isComplexType(type: any): boolean {
        return isListType(type);
    }

    isInputObjectType(type: any): boolean {
        return isInputObjectType(type);
    }

    isEnumType(type: any): boolean {
        return isEnumType(type);
    }

    getQueryArg(queryName: string, argName: string): GraphQLArgument {
        let queryArgs = this.getQueryArgs(queryName);
        return queryArgs?.find(a => a.name === argName);
    }

    getArgDefinition(arg: GraphQLArgument) {
        return {
            name: arg.name,
            type: arg.type.inspect(),
            fields: arg.type["ofType"]?.getFields() ?? []
        }
    }

    checkColumnSortable(operation: string, columnName: string): boolean {
        let argDef = this.getSortingDef(operation);
        return !!(argDef && argDef.fields && argDef.fields[columnName]);
    }

    getSortingDef(operation: string) {
        let queryArg = this.getQueryArg(operation, "order");
        return this.getArgDefinition(queryArg);
    }


    getEntityColumnInfo(entityName: string, columnName: string): FieldDefinition {
        let fields = this.getEntityFields(entityName);
        let filed: FieldDefinition = null;
        let fieldDef = fields[columnName];
        let fieldType = this.getFieldType(fieldDef);
        if (columnName.includes(".")) {
            filed = this._getEntityColumnInfo(columnName, fields);
        } else {
            let query: Query;
            if (isCompositeType(fieldType)) {
                query = this.getQueryForType(fieldType);
            }
            filed = {
                name: fieldDef?.name || columnName,
                description: fieldDef?.description,
                type: fieldDef?.type.inspect(),
                query: query,
                isScalarType: this.isScalarType(fieldType),
                isArray: isListType(fieldDef?.type),
                isComplexType: isCompositeType(fieldType),
                isNullableType: isNullableType(fieldType)
            };
        }
        return filed;
    }


    private getQueryForType(type: GraphQLType) {
        let queryDef: Query;
        let queries = this.getQueries().getFields();
        for (const key in queries) {
            let query = queries[key];
            let entityType = this.getFieldType(query);
            let entityTypeName = entityType["name"];
            let entityFields = this.getEntityFields(entityTypeName);
            if (entityTypeName === type["name"]) {
                let fields = this.mapFields(entityFields);
                queryDef = {
                    operation: query.name,
                    fields: fields
                };
                return queryDef;
            } else {
                continue;
            }
        }
        return null;
    }

    private mapFields(fields: GraphQLFieldMap<any, any>): Fields {
        let fieldsSet = [];
        for (const fieldsKey in fields) {
            let field = fields[fieldsKey];
            if (!isCompositeType(this.getFieldType(field))) {
                fieldsSet.push(fieldsKey);
            }
        }
        return fieldsSet;
    }


    private getFieldType(field: GraphQLField<any, any>): GraphQLType {
        return (field?.type["ofType"]) ? field?.type["ofType"] : field?.type;
    }

    private _getEntityColumnInfo(columnName: string, fields: GraphQLFieldMap<any, any>): FieldDefinition {
        let columnParts = columnName.split(".");
        let root = columnParts[0];
        let gqlField: any = fields[root] as GraphQLField<any, any>;
        let nextPart = columnParts[1];

        let fieldDef: FieldDefinition = {
            name: gqlField?.name || "",
            description: gqlField?.description || "",
            type: gqlField?.type?.inspect() || ""
        };

        if (nextPart) {
            let nestedObjFields = null;
            if (isNonNullType(gqlField.type)) {
                nestedObjFields = gqlField.type.ofType.getFields();
            } else if (isObjectType(gqlField.type)) {
                nestedObjFields = gqlField.type.getFields();
            }
            let nestedField = this._getEntityColumnInfo(nextPart, nestedObjFields);
            fieldDef = {
                name: `${fieldDef.name}.${nestedField.name}`,
                description: `${fieldDef.description}.${nestedField.description}`,
                type: `${fieldDef.type}.${nestedField.type}`
            }
        }
        return fieldDef;

    }


    getQueryEntity(queryName: string): string {
        let query = this.getQuery(queryName);
        let namedType = getNamedType(query.type);
        return namedType.name;
    }

    private getEntity(entityName: string): GraphQLObjectType {
        return this.schema.getType(entityName) as GraphQLObjectType;
    }

    private getEntityFields(entityName: string): GraphQLFieldMap<any, any> {
        return this.getEntity(entityName).getFields();
    }
}
