import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

import {environment} from "../../../environments/environment";
import {IApiResponse} from "@app/shared/models/gql.response";
import {Setting} from "@app/models/types";
import {FacebookLoginQueryParams} from "@app/shared/models/facebook-login-query-params.type";


@Injectable({
    providedIn: 'root'
})
export class FacebookLoginService {

    private readonly FACEBOOK_DIALOG_OAUTH_URL: string = 'https://www.facebook.com/v17.0/dialog/oauth';
    private readonly FACEBOOK_CALLBACK_URL_SEGMENT: string = '/app/facebook-callback';


    constructor(private http: HttpClient,
                private router: Router) {
    }


    login(): void {
        this.getInstagramAppId().subscribe((instagramAppId: string) => {
            const queryParams: FacebookLoginQueryParams = {
                client_id: instagramAppId,
                redirect_uri: window.location.origin + this.FACEBOOK_CALLBACK_URL_SEGMENT,
                state: 'rz-connector-instagram-rozdesk',
                response_type: 'code',
                auth_type: 'rerequest',
                scope: 'public_profile,email,user_likes'
            };

            // &scope=public_profile,user_likes
            const facebookLoginUrl: string = this.FACEBOOK_DIALOG_OAUTH_URL + `?client_id=${queryParams.client_id}&redirect_uri=${queryParams.redirect_uri}&state=${queryParams.state}&response_type=${queryParams.response_type}`;

            localStorage.setItem(environment.localStorageName.locationHrefBeforeFacebookLogin, window.location.href);
            window.location.href = facebookLoginUrl;
        });
    }


    private getInstagramAppId(): Observable<string | null> {
        const query: string = `{
                  setting(codes: ["InstagramAppId"]) {
                    code
                    value
                  }
                }`;

        return this.http.post(environment.baseUrl, {query})
            .pipe(
                map((response: any) => {
                    return response?.data?.setting;
                }),
                map((response: Setting[]) => {
                    return response ? response[0] : null;
                }),
                map((response: Setting) => {
                    return response ? response.value : null;
                })
            );
    }


    handleCallback(code: string): Observable<any> {
        const query: string = `mutation {
                  facebookAccessToken(
                    channel: "Instagram"
                    code: "${code}"
                  )
                }`;

        return this.http.post<IApiResponse<any>>(environment.baseUrl, {query});
    }


    redirectToHrefBeforeFacebookLogin(): void {
        const locationHrefBeforeFacebookLogin: string = localStorage.getItem(environment.localStorageName.locationHrefBeforeFacebookLogin);

        if (locationHrefBeforeFacebookLogin) {
            localStorage.removeItem(environment.localStorageName.locationHrefBeforeFacebookLogin);

            const url: URL = new URL(locationHrefBeforeFacebookLogin);
            this.router.navigate([url.pathname]);
        }
    }


    removeFacebookData(): void {
        localStorage.removeItem(environment.localStorageName.locationHrefBeforeFacebookLogin);
    }

}
