import {Injectable} from "@angular/core";
import {HttpRequest, HttpHandler, HttpInterceptor, HttpEvent} from '@angular/common/http';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

import {ToastrService} from "ngx-toastr";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private toastr: ToastrService) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let {params} = request;
        let disableGlobalErrorCatch: string = params.get("disableGlobalErrorCatch");

        let cloneReq: HttpRequest<any> = request.clone({
            ...request,
            params: params.delete("disableGlobalErrorCatch")
        });

        if (disableGlobalErrorCatch) {
            return next.handle(cloneReq);
        }

        return next.handle(cloneReq).pipe(catchError(error => {
            this.toastr.error(error.message, "Error!");
            return throwError(error);
        }));
    }

}
