import {NgModule} from '@angular/core';

import {MAT_DATE_LOCALE} from '@angular/material/core';
import {NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular-material-components/moment-adapter';

import {
    NgxMatDatetimePickerModule, NGX_MAT_DATE_FORMATS, NgxMatDateFormats
} from '@angular-material-components/datetime-picker';

export const MOMENT_DATETIME: string = 'DD.MM.YYYY HH:mm';

const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: MOMENT_DATETIME,
    },
    display: {
        dateInput: MOMENT_DATETIME,
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    exports: [
        NgxMatMomentModule,
        NgxMatDatetimePickerModule,
    ],
    providers: [
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: CUSTOM_MOMENT_FORMATS
        },
        {
            provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {useUtc: true}
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'ru-RU'
        }
    ],
})
export class DatetimePikerMaterialModule {
}
