import {ActionReducerMap} from '@ngrx/store';
import {reducer as authReducer} from "@app/store/reducers/auth.reducer";


export interface AuthState {
    isLoggedIn: boolean;
    token: string | null,
    errors: string[] | null,
    userName: string | null
}


export interface State {
    auth: AuthState;
}


export const reducers: ActionReducerMap<State> = {
    auth: authReducer
};
